import React, { useState, useEffect, useContext} from "react"
import {Helmet} from "react-helmet";
import {
    Card,
    CardBody,
    Col,
    Container,
    Form,
    Row,
    Spinner,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumbclientdetails";
import Filter from "pages/Filter";
import { AuthContext } from "context/authContext";
import toast from "react-hot-toast";
import axios from "axios";
import AppConfig from "constants/config";
import moment from "moment";
import { capitalize } from "lodash";
import {commaSeperated} from "helpers/formatters"
import { useLocation } from "react-router-dom";
import ExcelJS from "exceljs";
import Layout from "components/VerticalLayout";
import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { getMuiTheme } from 'helpers/mui_theme'


const MappedJE = () => {
    const location = useLocation();
    const queryString = location.search;
    const sp = new URLSearchParams(queryString);
    const { userData, reloadProcessingSearch, isProcessingSearch, mode } = useContext(AuthContext)
    const [filter, setFilter] = useState(true);
    const [loading, setLoading] = useState(false);
    const [lesseeClients, setLesseeClients] = useState([]);
    const [lessorClients, setLessorClients] = useState([]);
    const [journalData, setJournalData] = useState([]);
    const [journalTotalData, setJournalTotalData] = useState({});
    const [loadingJournal, setLoadingJournal] = useState(true);
    const [jeType, setJEType] = useState('monthly');
    const [searchResult, setSearchResult] = useState({});
    const [accountType, setAccountType] = useState("");

    const columns = [
        {
          label: 'Date',
          name: 'date',
          options: {
            filter: false,
            sort: false,
            setCellProps: () => ({style: {minWidth: '100px',height: '40px'}}),
            setCellHeaderProps: () => ({style: {minWidth: '100px',height: '50px'}}),
            customBodyRenderLite:(dataIndex)=>(
              journalData && journalData[dataIndex].journal_entries ? moment.utc(journalData[dataIndex].journal_entries.date).format('MM/DD/YYYY') : moment.utc(journalData[dataIndex].entries.date).format('MM/DD/YYYY')
            ),
          }
        },
        {
          label: 'Client',
          name: 'client_id.client',
          options: {
            filter: false,
            sort: false,
            setCellProps: () => ({style: {minWidth: '100px'}}),
            setCellHeaderProps: () => ({style: {minWidth: '100px'}}),
            customBodyRenderLite:(dataIndex)=>(
              <div style={{whiteSpace:'normal'}}>
              {journalData && journalData[dataIndex] && journalData[dataIndex].client_id && journalData[dataIndex].client_id.client ? capitalize(journalData[dataIndex].client_id.client) : ""}
              </div>
            )
          }
        },
        {
          label: 'Lease Name',
          name: 'lease_id.name',
          options: {
            filter: false,
            sort: false,
            setCellProps: () => ({style: {minWidth: '200px'}}),
            setCellHeaderProps: () => ({style: {minWidth: '200px'}}),
            customBodyRenderLite:(dataIndex)=>(
              <div style={{whiteSpace:'normal'}}>
              {journalData && journalData[dataIndex] && journalData[dataIndex].lease_id && journalData[dataIndex].lease_id.name ? capitalize(journalData[dataIndex].lease_id.name) : ""}
              </div>
            )
          }
        },
        {
          label: 'Reporting Standard',
          name: 'lease_id.reporting_standard',
          options: {
            filter: false,
            sort: false,
            setCellProps: () => ({style: {minWidth: '150px'}}),
            setCellHeaderProps: () => ({style: {minWidth: '150px'}}),
            customBodyRenderLite:(dataIndex)=>(
              <div style={{whiteSpace:'normal'}}>
              {journalData && journalData[dataIndex] && journalData[dataIndex].lease_id && journalData[dataIndex].lease_id.reporting_standard ? capitalize(journalData[dataIndex].lease_id.reporting_standard) : ""}
              </div>
            )
          }
        },
        {
          label: 'Classification',
          name: 'lease_id.classification',
          options: {
            filter: false,
            sort: false,
            setCellProps: () => ({style: {minWidth: '150px'}}),
            setCellHeaderProps: () => ({style: {minWidth: '150px'}}),
            customBodyRenderLite:(dataIndex)=>(
              <div style={{whiteSpace:'normal'}}>
              {journalData && journalData[dataIndex] && journalData[dataIndex].lease_id && journalData[dataIndex].lease_id.classification ? capitalize(journalData[dataIndex].lease_id.classification) : ""}
              </div>
            )
          }
        },
        {
          label: 'Description',
          name: 'entries.description',
          options: {
            filter: false,
            sort: false,
            setCellProps: () => ({style: {minWidth: '250px'}}),
            setCellHeaderProps: () => ({style: {minWidth: '250px'}}),
            customBodyRenderLite:(dataIndex)=>(
              journalData && journalData[dataIndex] && journalData[dataIndex].entries && journalData[dataIndex].entries.description ? journalData[dataIndex].entries.description : ""
            ),
          }
        },
        {
          label: 'Currency',
          name: 'currency.symbol',
          options: {
            filter: false,
            sort: false,
            setCellProps: () => ({style: {minWidth: '100px'}}),
            setCellHeaderProps: () => ({style: {minWidth: '100px'}}),
            customBodyRenderLite:(dataIndex)=>(
              journalData && journalData[dataIndex] && journalData[dataIndex].currency && journalData[dataIndex].currency.symbol ? journalData[dataIndex].currency.symbol + '('+ journalData[dataIndex].currency.code +')' : "$ (USD)"
            ),
          }
        },
        {
          label: 'Account Number',
          name: 'entries.account_no',
          options: {
            filter: false,
            sort: false,
            setCellProps: () => ({style: {minWidth: '150px'}}),
            setCellHeaderProps: () => ({style: {minWidth: '150px'}}),
            customBodyRenderLite:(dataIndex)=>(
              journalData && journalData[dataIndex] && journalData[dataIndex].entries && journalData[dataIndex].entries.account_no ? journalData[dataIndex].entries.account_no : ''
            ),
          }
        },
        {
          label: 'Account Description',
          name: 'entries.account_description',
          options: {
            filter: false,
            sort: false,
            setCellProps: () => ({style: {minWidth: '250px'}}),
            setCellHeaderProps: () => ({style: {minWidth: '250px'}}),
            customBodyRenderLite:(dataIndex)=>(
              journalData && journalData[dataIndex] && journalData[dataIndex].entries && journalData[dataIndex].entries.account_description ? journalData[dataIndex].entries.account_description : ''
            ),
          }
        },
        {
          label: 'Allocation Type',
          name: 'entries.allocation_type',
          options: {
            filter: false,
            sort: false,
            setCellProps: () => ({style: {minWidth: '150px'}}),
            setCellHeaderProps: () => ({style: {minWidth: '150px'}}),
            customBodyRenderLite:(dataIndex)=>(
              journalData && journalData[dataIndex] && journalData[dataIndex].entries && journalData[dataIndex].entries.allocation_type ? journalData[dataIndex].entries.allocation_type : ''
            ),
          }
        },
        {
          label: `Allocation Number`,
          name: 'entries.allocation_no',
          options: {
            filter: false,
            sort: false,
            setCellProps: () => ({style: {minWidth: '150px'}}),
            setCellHeaderProps: () => ({style: {minWidth: '150px'}}),
            customBodyRenderLite:(dataIndex)=>(
              journalData && journalData[dataIndex] && journalData[dataIndex].entries && journalData[dataIndex].entries.allocation_no ? journalData[dataIndex].entries.allocation_no : ''
            ),
          }
        },
        {
          label: `Allocation Description`,
          name: 'entries.allocation_description',
          options: {
            filter: false,
            sort: false,
            setCellProps: () => ({style: {minWidth: '200px'}}),
            setCellHeaderProps: () => ({style: {minWidth: '200px'}}),
            customBodyRenderLite:(dataIndex)=>(
              journalData && journalData[dataIndex] && journalData[dataIndex].entries && journalData[dataIndex].entries.allocation_description ? journalData[dataIndex].entries.allocation_description : ''
            ),
          }
        },
        {
          label: `Financial statement line item`,
          name: 'entries.fsli',
          options: {
            filter: false,
            sort: false,
            setCellProps: () => ({style: {minWidth: '250px'}}),
            setCellHeaderProps: () => ({style: {minWidth: '250px'}}),
            customBodyRenderLite:(dataIndex)=>(
              journalData && journalData[dataIndex] && journalData[dataIndex].entries && journalData[dataIndex].entries.fsli ? journalData[dataIndex].entries.fsli : ''
            ),
          }
        },
        {
          label: `Amount`,
          name: 'entries.amount',
          options: {
            filter: false,
            sort: false,
            setCellProps: () => ({style: {minWidth: '100px'}}),
            setCellHeaderProps: () => ({style: {minWidth: '100px'}}),
            customBodyRenderLite:(dataIndex)=>(
              journalData && journalData[dataIndex] && journalData[dataIndex].entries && journalData[dataIndex].entries.amount && Number(Number(journalData[dataIndex].entries.amount).toFixed(2)) != 0 ? commaSeperated(Number(journalData[dataIndex].entries.amount)) : ''
            ),
          }
        },
        {
          label: `Amount (Absolute value)`,
          name: 'entries.amount',
          options: {
            filter: false,
            sort: false,
            setCellProps: () => ({style: {minWidth: '200px'}}),
            setCellHeaderProps: () => ({style: {minWidth: '200px'}}),
            customBodyRenderLite:(dataIndex)=>(
              journalData && journalData[dataIndex] && journalData[dataIndex].entries && journalData[dataIndex].entries.amount && Number(Number(journalData[dataIndex].entries.amount).toFixed(2)) != 0 ? commaSeperated(Number(Math.abs(journalData[dataIndex].entries.amount))) : ''
            ),
          }
        },
        {
          label: 'Debit/Credit',
          name: 'entries.amount',
          options: {
            filter: false,
            sort: false,
            setCellProps: () => ({style: {minWidth: '100px'}}),
            setCellHeaderProps: () => ({style: {minWidth: '100px'}}),
            customBodyRenderLite:(dataIndex)=>(
              journalData && journalData[dataIndex] && journalData[dataIndex].entries && Number(Number(journalData[dataIndex].entries.amount).toFixed(2)) > 0 ? 'Debit' : journalData && journalData[dataIndex] && journalData[dataIndex].entries.amount < 0 ? 'Credit' : ''
            ),
          }
        },
        {
          label: 'Debit',
          name: 'entries.amount',
          options: {
            filter: false,
            sort: false,
            setCellProps: () => ({style: {minWidth: '100px'}}),
            setCellHeaderProps: () => ({style: {minWidth: '100px'}}),
            customBodyRenderLite:(dataIndex)=>(
              journalData && journalData[dataIndex] && journalData[dataIndex].entries && Number(Number(journalData[dataIndex].entries.amount).toFixed(2)) > 0 ? commaSeperated(Number(journalData[dataIndex].entries.amount)) : ''
            ),
          }
        },
        {
          label: 'Credit',
          name: 'entries.amount',
          options: {
            filter: false,
            sort: false,
            setCellProps: () => ({style: {minWidth: '100px'}}),
            setCellHeaderProps: () => ({style: {minWidth: '100px'}}),
            customBodyRenderLite:(dataIndex)=>(
              journalData && journalData[dataIndex] && journalData[dataIndex].entries && Number(Number(journalData[dataIndex].entries.amount).toFixed(2)) < 0 ? commaSeperated(Number(Math.abs(journalData[dataIndex].entries.amount))) : ''
            ),
          }
        }
    ]

    useEffect(()=>{
      if(sp.get("id")){
        setFilter(false);
        getSearchResult(sp.get("id"), 'delete')
      }
    },[location.search])

    useEffect(()=>{
      if(searchResult && searchResult._id && !isProcessingSearch){
        getSearchResult(searchResult._id, 'delete')
      }
    },[isProcessingSearch])

    useEffect(()=>{
      if(mode && !filter){
        setFilter(true)
        setJournalData([]);
        setJournalTotalData({});
      }
    },[mode])

    const handleChangeJeType = (e) => {
      setJEType(e.target.value)
    }

    const getClientsData = async(filter) =>{
        setLesseeClients([]);
        setLessorClients([]);
        setLoading(true);
        try {
            const {data} = await axios.get(`${AppConfig.baseUrl}/user_client/get_users_client?user_id=${userData.id}&access_type=${filter.access_type || ""}&search=${filter.search || ""}`,{
                headers: { token: localStorage.getItem("token") }
            });
            if(!data.error){
                const lessee = data.clientData ? data.clientData.filter(l => l.client_id.client_type == 'lessee') : []
                setLesseeClients(lessee);
                const lessor = data.clientData ? data.clientData.filter(l => l.client_id.client_type == 'lessor') : []
                setLessorClients(lessor);
            }
            else{
                throw new Error(data.title);
            }
        } catch (error) {
            toast.error(error.message || 'Something went wrong');
        }
        setLoading(false)
    }
  
    const getJournalData = async(start, end, leases, clients) =>{
        setLoadingJournal(true);
        try {
            const body = {
                start_date: moment(start).format("YYYY-MM-DD"),
                end_date: moment(end).format("YYYY-MM-DD"),
                lease_ids: leases,
                type: jeType,
                clients,
                mode: mode ? mode : 'lessee',
                je_type: 'glmap',
            }
            const {data} = await axios.post(`${AppConfig.baseUrl}/journal_entries/get_journal_entries`,body, {
                headers: { token: localStorage.getItem("token") }
            });
            if(!data.error){
              getSearchResult(data.searchData._id);
              reloadProcessingSearch();
            }else{
              throw new Error(data.title);
            }
        } catch (error) {
            toast.error(error.message || 'Something went wrong');
            setLoadingJournal(false)
        }
    }
  
    useEffect(() => {
        if(userData && userData.id){
            getClientsData({});
        }
    }, [userData])

    const flip = (selectedLeases, startDate, endDate) => {
        let uniqueClients = [];
        let ids = []
        for(const lease of selectedLeases){
            ids.push(lease._id)
            const client = lease.client_id
            const index = uniqueClients.findIndex(v => v === lease.client_id._id)
            if(index === -1){
                uniqueClients.push(client._id);
            }
        }
        setFilter(false);
        getJournalData(startDate, endDate, ids, uniqueClients);
    }

    const getSearchResult = async(id, type) => {
      setLoadingJournal(true);
      setFilter(false)
      setJournalData([]);
      setJournalTotalData({});
      try {
        const {data} = await axios.get(`${AppConfig.baseUrl}/search_query?id=${id}&type=${type}&mode=${mode ? mode : 'lessee'}`,{
          headers: { token: localStorage.getItem("token") }
        });
        if(!data.error){
          setSearchResult(data.searchResult)
          let chunkData = data.searchResult && data.searchResult.result && data.searchResult.result.journal ? data.searchResult.result.journal : []
          if(data.searchChunk && data.searchChunk.length > 0 ){
            for(let v of data.searchChunk){
              if(v.result && v.result.journal && v.result.journal.length > 0){
                chunkData = [...chunkData,...v.result.journal]
              }
            }
          }
          let mapData = chunkData && chunkData.filter(d => d.entries.amount != 0)
          setJournalData(mapData);
          setJournalTotalData(data.searchResult && data.searchResult.result && data.searchResult.result.journalTotal && data.searchResult.result.journalTotal.length > 0 ? data.searchResult.result.journalTotal[0] : {});
        }
        else{
          throw new Error(data.title);
        }
      } catch (error) {
        toast.error(error.message || 'Something went wrong');
      }
      setLoadingJournal(false);
    }
    const downloadCSVFile = () => {
        var ExcelJSWorkbook = new ExcelJS.Workbook();
        var worksheet = ExcelJSWorkbook.addWorksheet("GL_Map_Journal_Entries");
        worksheet.getColumn("A").width = 10
        worksheet.getColumn("B").width = 10
        worksheet.getColumn("C").width = 10
        worksheet.getColumn("D").width = 10
        worksheet.getColumn("E").width = 10
        worksheet.getColumn("F").width = 10
        worksheet.getColumn("G").width = 10
        worksheet.getColumn("H").width = 10
        worksheet.getColumn("I").width = 10
        worksheet.getColumn("J").width = 10
        worksheet.getColumn("K").width = 10
        worksheet.getColumn("L").width = 10
        worksheet.getColumn("M").width = 10
        worksheet.getColumn("N").width = 10
        worksheet.getColumn("O").width = 10
        worksheet.getColumn("P").width = 10
        worksheet.getColumn("Q").width = 10
        worksheet.getColumn("R").width = 10
        
        worksheet.getCell("A1").value = `Date`        
        worksheet.getCell("B1").value = `Client`        
        worksheet.getCell("C1").value = `Lease Name`        
        worksheet.getCell("D1").value = `Reporting Standard`        
        worksheet.getCell("E1").value = `Classification`         
        worksheet.getCell("F1").value = `Description` 
        worksheet.getCell("G1").value = `Currency`
        worksheet.getCell("H1").value = `Account Number` 
        worksheet.getCell("I1").value = `Account Description`
        worksheet.getCell("J1").value = `Allocation Type`
        worksheet.getCell("K1").value = `Allocation Number`
        worksheet.getCell("L1").value = `Allocation Description`
        worksheet.getCell("M1").value = `Financial statement line item`
        worksheet.getCell("N1").value = `Amount`
        worksheet.getCell("O1").value = `Amount (Absolute value)`
        worksheet.getCell("P1").value = `Debit/Credit`
        worksheet.getCell("Q1").value = `Debit`
        worksheet.getCell("R1").value = `Credit`

        journalData && journalData.length > 0 && journalData.map((value, i) => {
          worksheet.addRow([ value.journal_entries ? new Date(value.journal_entries.date) : new Date(value.entries.date), value.client_id.client,value.lease_id.name, value.lease_id.reporting_standard, value && value.lease_id && value.lease_id.classification ? capitalize(value.lease_id.classification) : "",value && value.entries && value.entries.description ? value.entries.description : "", value && value.currency && value.currency.symbol ? value.currency.symbol + '('+ value.currency.code +')' : "$ (USD)", 
          value && value.entries && value.entries.account_no ? value.entries.account_no : value && value.journal_entries && value.journal_entries.account_no ? value.journal_entries.account_no: "",
          value && value.entries && value.entries.account_description ? value.entries.account_description : value && value.journal_entries && value.journal_entries.account_description ? value.journal_entries.account_description :  "", 
          value && value.entries && value.entries.allocation_type ? value.entries.allocation_type : value && value.journal_entries && value.journal_entries.allocation_type ? value.journal_entries.allocation_type : "",
          value && value.entries && value.entries.allocation_no ? value.entries.allocation_no : value && value.journal_entries && value.journal_entries.allocation_no ? value.journal_entries.allocation_no : "",
          value && value.entries && value.entries.allocation_description ? value.entries.allocation_description : value && value.journal_entries && value.journal_entries.allocation_description ? value.journal_entries.allocation_description : "",
          value && value.entries && value.entries.fsli ? value.entries.fsli : value && value.journal_entries && value.journal_entries.fsli ? value.journal_entries.fsli : "" ,
          value && value.entries && value.entries.amount ? Number(value.entries.amount) : value && value.journal_entries && value.journal_entries.amount ? Number(value.journal_entries.amount) : "",
          value && value.entries && value.entries.amount ? Number(Math.abs(value.entries.amount)) : value && value.journal_entries && value.journal_entries.amount ? Number(Math.abs(value.journal_entries.amount)) : "",
          value && value.entries && value.entries.amount && value.entries.amount > 0 ? 'Debit' : value && value.entries && value.entries.amount && value.entries.amount < 0 ? 'Credit' : value && value.journal_entries && value.journal_entries.amount && value.journal_entries.amount > 0 ? 'Debit' : value && value.journal_entries && value.journal_entries.amount && value.journal_entries.amount < 0 ? 'Credit' : '',
          value && value.entries && value.entries.amount && value.entries.amount > 0 ? Number(value.entries.amount) : value && value.journal_entries && value.journal_entries.amount && value.journal_entries.amount > 0 ? Number(value.journal_entries.amount) : "",
          value && value.entries && value.entries.amount && value.entries.amount < 0 ? Number(Math.abs(value.entries.amount)) : value && value.journal_entries && value.journal_entries.amount && value.journal_entries.amount < 0 ? Number(Math.abs(value.journal_entries.amount)) : "" ]);
        })
    
        worksheet.getColumn(1).numFmt = 'mm/dd/yyyy'
        worksheet.getColumn(14).numFmt = '##,##,##,##,##0.00'
        worksheet.getColumn(15).numFmt = '##,##,##,##,##0.00'
        worksheet.getColumn(16).numFmt = '##,##,##,##,##0.00'
        worksheet.getColumn(17).numFmt = '##,##,##,##,##0.00'
        worksheet.getColumn(18).numFmt = '##,##,##,##,##0.00'
    
        ExcelJSWorkbook.xlsx.writeBuffer().then(function(buffer) {
            var csv_file, download_link;
            csv_file = new Blob([buffer], { type: "application/octet-stream" });
            download_link = document.createElement("a");
            download_link.download = "GL_Map_Journal_Entries.xlsx" ;
            download_link.href = window.URL.createObjectURL(csv_file);
            download_link.style.display = "none";
            document.body.appendChild(download_link);
            download_link.click();
        });
    }

    return (
        <Layout>
            {filter ?
                <div className="page-content">
                    <Helmet>
                        <title>LeaseJava | Journal Entries</title>
                    </Helmet>
                    <Container fluid>
                        <Breadcrumbs title="journal_filter"
                            name="G/L mapped Dr / Cr View"
                            type="journal_filter"
                        />
                    </Container>
                    <Filter handleClick={flip} type="mapped_je" clientsData={mode == 'lessor' ? lessorClients : lesseeClients} loading={loading} jeType={jeType} accountType={accountType} setAccountType={setAccountType} setJEType={setJEType} handleChangeJeType={handleChangeJeType} getSearchResult={getSearchResult}/> 
                </div>
                :
                <div className="page-content">
                    <Helmet>
                        <title>LeaseJava | Journal Entries</title>
                    </Helmet>
                    <Container fluid>
                        <Breadcrumbs title="journal"
                            name1="G/L mapped Dr / Cr View"
                            type="journal"
                        />
                        {searchResult && Object.keys(searchResult).length > 0 &&
                          <Row>
                            <Col md="6">
                              <Card>
                                  <CardBody>
                                    <Row>
                                      <Col xs="4">
                                          <h6>Date range:</h6>
                                      </Col>
                                      <Col xs="8" style={{textAlign: 'right'}}>
                                          {moment(searchResult.startDate).utc().format('MM/DD/YYYY')}-{moment(searchResult.endDate).utc().format('MM/DD/YYYY')}
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col xs="4">
                                          <h6>Type:</h6>
                                      </Col>
                                      <Col xs="8" style={{textAlign: 'right'}}>
                                          {searchResult.je_type.toUpperCase()}
                                      </Col>
                                    </Row>
                                  </CardBody>
                              </Card>
                            </Col>
                            <Col md="6">
                              <Card>
                                  <CardBody>
                                    <Row>
                                      <Col xs="4">
                                          <h6>Clients:</h6>
                                      </Col>
                                      <Col xs="8" style={{textAlign: 'right'}}>
                                          {searchResult.clients && searchResult.clients.length > 1 ? searchResult.clients[0].client+", "+searchResult.clients[1].client+(searchResult.clients.length > 2 ? " + "+(searchResult.clients.length - 2)+" others" : '') : searchResult.clients && searchResult.clients.length > 0 ? searchResult.clients[0].client : ''}
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col xs="4">
                                          <h6>Leases:</h6>
                                      </Col>
                                      <Col xs="8" style={{textAlign: 'right'}}>
                                          {searchResult.lease_ids && searchResult.lease_ids.length > 1 ? searchResult.lease_ids[0].name+", "+searchResult.lease_ids[1].name+(searchResult.lease_ids.length > 2 ? " + "+(searchResult.lease_ids.length - 2)+" others" : '') : searchResult.lease_ids && searchResult.lease_ids.length > 0 ? searchResult.lease_ids[0].name : ''}
                                      </Col>
                                    </Row>
                                  </CardBody>
                              </Card>
                            </Col>
                          </Row>
                        }
                        <Row>
                          <Col>
                            <Card>
                                <CardBody>
                                    <ThemeProvider theme={createTheme({components: {...getMuiTheme(),
                                      MUIDataTableToolbar:{
                                        styleOverrides:{
                                          actions: {
                                            display: 'contents'
                                          }
                                        }
                                      }
                                    }})}>
                                      <MUIDataTable
                                        title={
                                          <Form>
                                            <div className="d-flex mb-3 justify-content-end">
                                                <button type="button" disabled={loadingJournal} onClick={()=>{downloadCSVFile()}} className="btn btn-lbusers btn-md">
                                                    {
                                                        loadingJournal && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> 
                                                    }
                                                    EXPORT CSV
                                                </button>&nbsp;&nbsp;
                                            </div>
                                          </Form>
                                        }
                                        data={journalData}
                                        columns={columns}
                                        options={{ 
                                          rowsPerPage:10, 
                                          print:false, 
                                          download:false, 
                                          filter:false, 
                                          pagination:false,
                                          selectableRows:"none", 
                                          search:false, 
                                          rowsPerPageOptions:[], 
                                          responsive:'scroll',
                                          textLabels: {
                                            body: { 
                                              noMatch: loadingJournal ? <Spinner color="primary" className="text-lbusers" /> : !loadingJournal && searchResult && searchResult.is_processing ? <div><h6>Your data is being processed</h6><Spinner color="primary" className="text-lbusers" /></div> : "No data found"
                                            },
                                            viewColumns: {
                                              title: "",
                                            },
                                          },
                                          rowHover: false,
                                          setRowProps: (row, dataIndex, rowIndex) => {
                                            return {
                                              style: { backgroundColor: rowIndex % 2 === 0 ? '#ffffff' : '#eeeeee'},
                                            };
                                          },
                                        }}
                                      />
                                    </ThemeProvider>
                                </CardBody>
                            </Card>
                          </Col>
                        </Row>
                    </Container>
                </div>
            }
        </Layout>
    )
}

export default MappedJE