import React, { useState, useEffect, useContext} from "react"
import {Helmet} from "react-helmet";
import { Card, CardBody, Col, Container, Form, Row, Spinner } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumbclientdetails";
import Filter from "pages/Filter";
import { AuthContext } from "context/authContext";
import toast from "react-hot-toast";
import axios from "axios";
import AppConfig from "constants/config";
import moment from "moment";
import { capitalize } from "lodash";
import {commaSeperated} from "helpers/formatters"
import { useLocation } from "react-router-dom";
import ExcelJS from "exceljs";
import Layout from "components/VerticalLayout";
import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { getMuiTheme } from 'helpers/mui_theme'

const JournalEntries = () => {
    const location = useLocation();
    const queryString = location.search;
    const sp = new URLSearchParams(queryString);
    const { userData, reloadProcessingSearch, isProcessingSearch, mode } = useContext(AuthContext)
    const [filter, setFilter] = useState(true);
    const [loading, setLoading] = useState(false);
    const [lesseeClients, setLesseeClients] = useState([]);
    const [lessorClients, setLessorClients] = useState([]);
    const [journalData, setJournalData] = useState([]);
    const [loadingJournal, setLoadingJournal] = useState(true);
    const [jeType, setJEType] = useState('monthly');
    const [searchResult, setSearchResult] = useState({});
    const [accountType, setAccountType] = useState("");
    const [jeExportType, setJeExportType] = useState('monthly_aggregate');
    const [index, setIndex] = useState(0)

    const handleChangeType = (e) => {
      setJeExportType(e.target.value)
    }

    const columns = mode == 'lessor' ? [
        {
          label: 'Date',
          name: 'date',
          options: {
            filter: false,          
            sort: false,
            setCellProps: () => ({style: {minWidth: '100px',height: '40px'}}),
            setCellHeaderProps: () => ({style: {minWidth: '100px'}}),
            customBodyRenderLite:(dataIndex)=>(
              journalData && journalData[dataIndex].journal_entries && journalData[dataIndex].journal_entries.date ? moment.utc(journalData[dataIndex].journal_entries.date).format('MM/DD/YYYY') : journalData && journalData[dataIndex].entries && journalData[dataIndex].entries.date ? moment.utc(journalData[dataIndex].entries.date).format('MM/DD/YYYY') : 'Total'
            ),
          }
        },
        {
          label: 'Client',
          name: 'client_id.client',
          options: {
            filter: false,            
            sort: false,
            setCellProps: () => ({style: {minWidth: '100px'}}),
            setCellHeaderProps: () => ({style: {minWidth: '100px'}}),
            customBodyRenderLite:(dataIndex)=>(
              journalData && journalData[dataIndex] && journalData[dataIndex].client_id && journalData[dataIndex].client_id.client ? capitalize(journalData[dataIndex].client_id.client) : ""
            )
          }
        },
        {
          label: 'Lease Name',
          name: 'lease_id.name',
          options: {
            filter: false,         
            sort: false,
            setCellProps: () => ({style: {minWidth: '200px'}}),
            setCellHeaderProps: () => ({style: {minWidth: '200px'}}),
            customBodyRenderLite:(dataIndex)=>(
              journalData && journalData[dataIndex] && journalData[dataIndex].lease_id && journalData[dataIndex].lease_id.name ? capitalize(journalData[dataIndex].lease_id.name) : ""
            )
          }
        },
        {
          label: 'Reporting Standard',
          name: 'lease_id.reporting_standard',
          options: {
            filter: false,         
            sort: false,
            setCellProps: () => ({style: {minWidth: '150px'}}),
            setCellHeaderProps: () => ({style: {minWidth: '150px'}}),
            customBodyRenderLite:(dataIndex)=>(
              journalData && journalData[dataIndex] && journalData[dataIndex].lease_id && journalData[dataIndex].lease_id.reporting_standard ? capitalize(journalData[dataIndex].lease_id.reporting_standard) : ""
            )
          }
        },
        {
          label: 'Classification',
          name: 'lease_id.classification',
          options: {
            filter: false,         
            sort: false,
            setCellProps: () => ({style: {minWidth: '100px'}}),
            setCellHeaderProps: () => ({style: {minWidth: '100px'}}),
            customBodyRenderLite:(dataIndex)=>(
              journalData && journalData[dataIndex] && journalData[dataIndex].lease_id && journalData[dataIndex].lease_id.classification ? capitalize(journalData[dataIndex].lease_id.classification) : ""
            )
          }
        },
        {
          label: 'Description',
          name: 'entries.description',
          options: {
            filter: false,         
            sort: false,
            setCellProps: () => ({style: {minWidth: '250px'}}),
            setCellHeaderProps: () => ({style: {minWidth: '250px'}}),
            customBodyRenderLite:(dataIndex)=>(
              journalData && journalData[dataIndex] && journalData[dataIndex].entries && journalData[dataIndex].entries.description ? journalData[dataIndex].entries.description : ""
            ),
          }
        },
        {
          label: 'Currency',
          name: 'currency.symbol',
          options: {
            filter: false,         
            sort: false,
            setCellProps: () => ({style: {minWidth: '100px'}}),
            setCellHeaderProps: () => ({style: {minWidth: '100px'}}),
            customBodyRenderLite:(dataIndex)=>(
              journalData && journalData[dataIndex] && journalData[dataIndex].lease_id && !journalData[dataIndex].lease_id.reporting_standard ? '' : journalData && journalData[dataIndex] && journalData[dataIndex].currency && journalData[dataIndex].currency.symbol ? journalData[dataIndex].currency.symbol + '('+ journalData[dataIndex].currency.code +')' : "$ (USD)"
            ),
          }
        },
        {
          label: 'Receipt Suspense Account Before Commencement',
          name: 'entries.payment_suspense_account_before_commencement',
          options: {
            filter: false,         
            sort: false,
            setCellProps: () => ({style: {minWidth: '200px'}}),
            setCellHeaderProps: () => ({style: {minWidth: '200px'}}),
            customBodyRenderLite:(dataIndex)=>(
              journalData && journalData[dataIndex] && journalData[dataIndex].entries && journalData[dataIndex].entries.payment_suspense_account_before_commencement ? commaSeperated(journalData[dataIndex].entries.payment_suspense_account_before_commencement) :
              journalData && journalData[dataIndex] && journalData[dataIndex].journal_entries && journalData[dataIndex].journal_entries.payment_suspense_account_before_commencement ? commaSeperated(journalData[dataIndex].journal_entries.payment_suspense_account_before_commencement) : ""
            ),
          }
        },
        {
          label: 'Rent Deposit',
          name: 'rent_deposit',
          options: {
            filter: false,         
            sort: false,
            setCellProps: () => ({style: {minWidth: '150px'}}),
            setCellHeaderProps: () => ({style: {minWidth: '150px'}}),
            customBodyRenderLite:(dataIndex)=>(
              journalData && journalData[dataIndex] && journalData[dataIndex].entries && journalData[dataIndex].entries.rent_deposit ? commaSeperated(journalData[dataIndex].entries.rent_deposit) :
              journalData && journalData[dataIndex] && journalData[dataIndex].journal_entries && journalData[dataIndex].journal_entries.rent_deposit ? commaSeperated(journalData[dataIndex].journal_entries.rent_deposit) :  ""
            ),
          }
        },
        {
          label: 'Rent Receipt Suspense Account',
          name: 'rent_payment_suspense_account',
          options: {
            filter: false,         
            sort: false,
            setCellProps: () => ({style: {minWidth: '150px'}}),
            setCellHeaderProps: () => ({style: {minWidth: '150px'}}),
            customBodyRenderLite:(dataIndex)=>(
              journalData && journalData[dataIndex] && journalData[dataIndex].entries && journalData[dataIndex].entries.rent_payment_suspense_account ? commaSeperated(journalData[dataIndex].entries.rent_payment_suspense_account) :
              journalData && journalData[dataIndex] && journalData[dataIndex].journal_entries && journalData[dataIndex].journal_entries.rent_payment_suspense_account ? commaSeperated(journalData[dataIndex].journal_entries.rent_payment_suspense_account) : ""
            ),
          }
        },
        {
          label: `${mode == 'lessor' ? "Deferred inflow of resources" : "Rou Asset"}`,
          name: 'rou_asset',
          options: {
            filter: false,         
            sort: false,
            setCellProps: () => ({style: {minWidth: '150px'}}),
            setCellHeaderProps: () => ({style: {minWidth: '150px'}}),
            customBodyRenderLite:(dataIndex)=>(
              journalData && journalData[dataIndex] && journalData[dataIndex].entries && journalData[dataIndex].entries.rou_asset ? commaSeperated(journalData[dataIndex].entries.rou_asset) :
              journalData && journalData[dataIndex] && journalData[dataIndex].journal_entries && journalData[dataIndex].journal_entries.rou_asset ? commaSeperated(journalData[dataIndex].journal_entries.rou_asset) : ""
            ),
          }
        },
        {
          label: `${mode == 'lessor' ? "Receivable" : "Lease liability"}`,
          name: 'lease_liability',
          options: {
            filter: false,         
            sort: false,
            setCellProps: () => ({style: {minWidth: '150px'}}),
            setCellHeaderProps: () => ({style: {minWidth: '150px'}}),
            customBodyRenderLite:(dataIndex)=>(
              journalData && journalData[dataIndex] && journalData[dataIndex].entries && journalData[dataIndex].entries.lease_liability ? commaSeperated(journalData[dataIndex].entries.lease_liability) :
              journalData && journalData[dataIndex] && journalData[dataIndex].journal_entries && journalData[dataIndex].journal_entries.lease_liability ? commaSeperated(journalData[dataIndex].journal_entries.lease_liability) : ""
            ),
          }
        },
        {
          label: `Lease asset-liability`,
          name: 'differed_value',
          options: {
            filter: false,         
            sort: false,
            setCellProps: () => ({style: {minWidth: '200px'}}),
            setCellHeaderProps: () => ({style: {minWidth: '200px'}}),
            customBodyRenderLite:(dataIndex)=>(
              journalData && journalData[dataIndex] && journalData[dataIndex].differed_value && journalData[dataIndex].differed_value && Number(Number(journalData[dataIndex].differed_value).toFixed(2)) != 0 ? commaSeperated(journalData[dataIndex].differed_value) : ""
            ),
          }
        },
        {
          label: `${mode == 'lessor' ? 'Rent Revenue' : 'Rent Expense'}`,
          name: 'rent_expense',
          options: {
            filter: false,         
            sort: false,
            setCellProps: () => ({style: {minWidth: '150px'}}),
            setCellHeaderProps: () => ({style: {minWidth: '150px'}}),
            customBodyRenderLite:(dataIndex)=>(
                journalData && journalData[dataIndex] && journalData[dataIndex].entries && journalData[dataIndex].entries.rent_expense ? commaSeperated(journalData[dataIndex].entries.rent_expense) :
                journalData && journalData[dataIndex] && journalData[dataIndex].journal_entries && journalData[dataIndex].journal_entries.rent_expense ? commaSeperated(journalData[dataIndex].journal_entries.rent_expense) : "" 
            ),
          }
        },
        {
          label: `${mode == 'lessor' ? 'Interest Revenue' : 'Interest Expense'}`,
          name: 'interest',
          options: {
            filter: false,         
            sort: false,
            setCellProps: () => ({style: {minWidth: '150px'}}),
            setCellHeaderProps: () => ({style: {minWidth: '150px'}}),
            customBodyRenderLite:(dataIndex)=>(
              journalData && journalData[dataIndex] && journalData[dataIndex].entries && journalData[dataIndex].entries.interest ? commaSeperated(journalData[dataIndex].entries.interest) :
              journalData && journalData[dataIndex] && journalData[dataIndex].journal_entries && journalData[dataIndex].journal_entries.interest ? commaSeperated(journalData[dataIndex].journal_entries.interest) : ""
            ),
          }
        },
        {
          label: `${mode == 'lessor' ? 'Lease Revenue' : 'Amort'}`,
          name: 'amort',
          options: {
            filter: false,         
            sort: false,
            setCellProps: () => ({style: {minWidth: '100px'}}),
            setCellHeaderProps: () => ({style: {minWidth: '100px'}}),
            customBodyRenderLite:(dataIndex)=>(
              journalData && journalData[dataIndex] && journalData[dataIndex].entries && journalData[dataIndex].entries.amort ? commaSeperated(journalData[dataIndex].entries.amort) :
              journalData && journalData[dataIndex] && journalData[dataIndex].journal_entries && journalData[dataIndex].journal_entries.amort ? commaSeperated(journalData[dataIndex].journal_entries.amort) : ""
            ),
          }
        },
        {
          label: 'Financing Variable Lease Expense',
          name: 'financing_variable_lease_expense',
          options: {
            filter: false,         
            sort: false,
            setCellProps: () => ({style: {minWidth: '250px'}}),
            setCellHeaderProps: () => ({style: {minWidth: '250px'}}),
            customBodyRenderLite:(dataIndex)=>(
              journalData && journalData[dataIndex] && journalData[dataIndex].entries && journalData[dataIndex].entries.financing_variable_lease_expense ? commaSeperated(journalData[dataIndex].entries.financing_variable_lease_expense) :
              journalData && journalData[dataIndex] && journalData[dataIndex].journal_entries && journalData[dataIndex].journal_entries.financing_variable_lease_expense ? commaSeperated(journalData[dataIndex].journal_entries.financing_variable_lease_expense) : ""
            ),
          }
        },
        {
          label: 'Modification Loss or Gain',
          name: 'modification',
          options: {
            filter: false,         
            sort: false,
            setCellProps: () => ({style: {minWidth: '200px'}}),
            setCellHeaderProps: () => ({style: {minWidth: '200px'}}),
            customBodyRenderLite:(dataIndex)=>(
              journalData && journalData[dataIndex] && journalData[dataIndex].entries && journalData[dataIndex].entries.modification ? commaSeperated(journalData[dataIndex].entries.modification) :
              journalData && journalData[dataIndex] && journalData[dataIndex].journal_entries && journalData[dataIndex].journal_entries.modification ? commaSeperated(journalData[dataIndex].journal_entries.modification) : ""
            ),
          }
        },
        {
          label: 'Transition',
          name: 'transition',
          options: {
            filter: false,         
            sort: false,
            setCellProps: () => ({style: {minWidth: '100px'}}),
            setCellHeaderProps: () => ({style: {minWidth: '100px'}}),
            customBodyRenderLite:(dataIndex)=>(
              journalData && journalData[dataIndex] && journalData[dataIndex].entries && journalData[dataIndex].entries.transition ? commaSeperated(journalData[dataIndex].entries.transition) :
              journalData && journalData[dataIndex] && journalData[dataIndex].journal_entries && journalData[dataIndex].journal_entries.transition ? commaSeperated(journalData[dataIndex].journal_entries.transition) : ""
            ),
          }
        },
        {
          label: 'Non leasing Component',
          name: 'nonlease',
          options: {
            filter: false,         
            sort: false,
            setCellProps: () => ({style: {minWidth: '200px'}}),
            setCellHeaderProps: () => ({style: {minWidth: '200px'}}),
            customBodyRenderLite:(dataIndex)=>(
              journalData && journalData[dataIndex] && journalData[dataIndex].entries && journalData[dataIndex].entries.nonlease ? commaSeperated(journalData[dataIndex].entries.nonlease) :
              journalData && journalData[dataIndex] && journalData[dataIndex].journal_entries && journalData[dataIndex].journal_entries.nonlease ? commaSeperated(journalData[dataIndex].journal_entries.nonlease) : ""
            ),
          }
        },
    ]
    : [
      {
        label: 'Date',
        name: 'date',
        options: {
          filter: false,    
          sort: false,
          setCellProps: () => ({style: {minWidth: '100px',height: '40px'}}),
          setCellHeaderProps: () => ({style: {minWidth: '100px'}}),
          customBodyRenderLite:(dataIndex)=>{
            return journalData && journalData[dataIndex].journal_entries && journalData[dataIndex].journal_entries.date ? moment.utc(journalData[dataIndex].journal_entries.date).format('MM/DD/YYYY') : journalData && journalData[dataIndex].entries && journalData[dataIndex].entries.date ? moment.utc(journalData[dataIndex].entries.date).format('MM/DD/YYYY') : 'Total'
          },
        }
      },
      {
        label: 'Client',
        name: 'client_id.client',
        options: {
          filter: false,         
          sort: false,
          setCellProps: () => ({style: {minWidth: '100px'}}),
          setCellHeaderProps: () => ({style: {minWidth: '100px'}}),
          customBodyRenderLite:(dataIndex)=>(
            journalData && journalData[dataIndex] && journalData[dataIndex].client_id && journalData[dataIndex].client_id.client ? capitalize(journalData[dataIndex].client_id.client) : ""
          )
        }
      },
      {
        label: 'Lease Name',
        name: 'lease_id.name',
        options: {
          filter: false,       
          sort: false,
          setCellProps: () => ({style: {minWidth: '200px'}}),
          setCellHeaderProps: () => ({style: {minWidth: '200px'}}),
          customBodyRenderLite:(dataIndex)=>(
            journalData && journalData[dataIndex] && journalData[dataIndex].lease_id && journalData[dataIndex].lease_id.name ? capitalize(journalData[dataIndex].lease_id.name) : ""
          )
        }
      },
      {
        label: 'Reporting Standard',
        name: 'lease_id.reporting_standard',
        options: {
          filter: false,       
          sort: false,
          setCellProps: () => ({style: {minWidth: '150px'}}),
          setCellHeaderProps: () => ({style: {minWidth: '150px'}}),
          customBodyRenderLite:(dataIndex)=>(
            journalData && journalData[dataIndex] && journalData[dataIndex].lease_id && journalData[dataIndex].lease_id.reporting_standard ? capitalize(journalData[dataIndex].lease_id.reporting_standard) : ""
          )
        }
      },
      {
        label: 'Classification',
        name: 'lease_id.classification',
        options: {
          filter: false,       
          sort: false,
          setCellProps: () => ({style: {minWidth: '100px'}}),
          setCellHeaderProps: () => ({style: {minWidth: '100px'}}),
          customBodyRenderLite:(dataIndex)=>(
            journalData && journalData[dataIndex] && journalData[dataIndex].lease_id && journalData[dataIndex].lease_id.classification ? capitalize(journalData[dataIndex].lease_id.classification) : ""
          )
        }
      },
      {
        label: 'Description',
        name: 'entries.description',
        options: {
          filter: false,       
          sort: false,
          setCellProps: () => ({style: {minWidth: '250px'}}),
          setCellHeaderProps: () => ({style: {minWidth: '250px'}}),
          customBodyRenderLite:(dataIndex)=>(
            journalData && journalData[dataIndex] && journalData[dataIndex].entries && journalData[dataIndex].entries.description ? journalData[dataIndex].entries.description : ""
          ),
        }
      },
      {
        label: 'Currency',
        name: 'currency.symbol',
        options: {
          filter: false,       
          sort: false,
          setCellProps: () => ({style: {minWidth: '100px'}}),
          setCellHeaderProps: () => ({style: {minWidth: '100px'}}),
          customBodyRenderLite:(dataIndex)=>(
            journalData && journalData[dataIndex] && journalData[dataIndex].lease_id && !journalData[dataIndex].lease_id.reporting_standard ? '' : journalData && journalData[dataIndex] && journalData[dataIndex].currency && journalData[dataIndex].currency.symbol ? journalData[dataIndex].currency.symbol + '('+ journalData[dataIndex].currency.code +')' : "$ (USD)"
          ),
        }
      },
      {
        label: 'Payment Suspense Account Before Commencement',
        name: 'entries.payment_suspense_account_before_commencement',
        options: {
          filter: false,      
          sort: false,
          setCellProps: () => ({style: {minWidth: '200px'}}),
          setCellHeaderProps: () => ({style: {minWidth: '200px'}}),
          customBodyRenderLite:(dataIndex)=>(
            journalData && journalData[dataIndex] && journalData[dataIndex].entries && journalData[dataIndex].entries.payment_suspense_account_before_commencement ? commaSeperated(journalData[dataIndex].entries.payment_suspense_account_before_commencement) :
            journalData && journalData[dataIndex] && journalData[dataIndex].journal_entries && journalData[dataIndex].journal_entries.payment_suspense_account_before_commencement ? commaSeperated(journalData[dataIndex].journal_entries.payment_suspense_account_before_commencement) : ""
          ),
        }
      },
      {
        label: 'Rent Deposit',
        name: 'rent_deposit',
        options: {
          filter: false,      
          sort: false,
          setCellProps: () => ({style: {minWidth: '150px'}}),
          setCellHeaderProps: () => ({style: {minWidth: '150px'}}),
          customBodyRenderLite:(dataIndex)=>(
            journalData && journalData[dataIndex] && journalData[dataIndex].entries && journalData[dataIndex].entries.rent_deposit ? commaSeperated(journalData[dataIndex].entries.rent_deposit) :
            journalData && journalData[dataIndex] && journalData[dataIndex].journal_entries && journalData[dataIndex].journal_entries.rent_deposit ? commaSeperated(journalData[dataIndex].journal_entries.rent_deposit) :  ""
          ),
        }
      },
      {
        label: 'Rent Payment Suspense Account',
        name: 'rent_payment_suspense_account',
        options: {
          filter: false,       
          sort: false,
          setCellProps: () => ({style: {minWidth: '150px'}}),
          setCellHeaderProps: () => ({style: {minWidth: '150px'}}),
          customBodyRenderLite:(dataIndex)=>(
            journalData && journalData[dataIndex] && journalData[dataIndex].entries && journalData[dataIndex].entries.rent_payment_suspense_account ? commaSeperated(journalData[dataIndex].entries.rent_payment_suspense_account) :
            journalData && journalData[dataIndex] && journalData[dataIndex].journal_entries && journalData[dataIndex].journal_entries.rent_payment_suspense_account ? commaSeperated(journalData[dataIndex].journal_entries.rent_payment_suspense_account) : ""
          ),
        }
      },
      {
        label: `${mode == 'lessor' ? "Deferred inflow of resources" : "Rou Asset"}`,
        name: 'rou_asset',
        options: {
          filter: false,    
          sort: false,
          setCellProps: () => ({style: {minWidth: '150px'}}),
          setCellHeaderProps: () => ({style: {minWidth: '150px'}}),
          customBodyRenderLite:(dataIndex)=>(
            journalData && journalData[dataIndex] && journalData[dataIndex].entries && journalData[dataIndex].entries.rou_asset ? commaSeperated(journalData[dataIndex].entries.rou_asset) :
            journalData && journalData[dataIndex] && journalData[dataIndex].journal_entries && journalData[dataIndex].journal_entries.rou_asset ? commaSeperated(journalData[dataIndex].journal_entries.rou_asset) : ""
          ),
        }
      },
      {
        label: `${mode == 'lessor' ? "Receivable" : "Lease liability"}`,
        name: 'lease_liability',
        options: {
          filter: false,    
          sort: false,
          setCellProps: () => ({style: {minWidth: '150px'}}),
          setCellHeaderProps: () => ({style: {minWidth: '150px'}}),
          customBodyRenderLite:(dataIndex)=>(
            journalData && journalData[dataIndex] && journalData[dataIndex].entries && journalData[dataIndex].entries.lease_liability ? commaSeperated(journalData[dataIndex].entries.lease_liability) :
            journalData && journalData[dataIndex] && journalData[dataIndex].journal_entries && journalData[dataIndex].journal_entries.lease_liability ? commaSeperated(journalData[dataIndex].journal_entries.lease_liability) : ""
          ),
        }
      },
      {
        label: `Lease asset-liability`,
        name: 'differed_value',
        options: {
          filter: false,       
          sort: false,
          setCellProps: () => ({style: {minWidth: '200px'}}),
          setCellHeaderProps: () => ({style: {minWidth: '200px'}}),
          customBodyRenderLite:(dataIndex)=>(
            journalData && journalData[dataIndex] && journalData[dataIndex].differed_value && journalData[dataIndex].differed_value && Number(Number(journalData[dataIndex].differed_value).toFixed(2)) != 0 ? commaSeperated(journalData[dataIndex].differed_value) : ""
          ),
        }
      },
      {
        label: `${mode == 'lessor' ? 'Rent Revenue' : 'Rent Expense'}`,
        name: 'rent_expense',
        options: {
          filter: false,      
          sort: false,
          setCellProps: () => ({style: {minWidth: '150px'}}),
          setCellHeaderProps: () => ({style: {minWidth: '150px'}}),
          customBodyRenderLite:(dataIndex)=>(
              journalData && journalData[dataIndex] && journalData[dataIndex].entries && journalData[dataIndex].entries.rent_expense ? commaSeperated(journalData[dataIndex].entries.rent_expense) :
              journalData && journalData[dataIndex] && journalData[dataIndex].journal_entries && journalData[dataIndex].journal_entries.rent_expense ? commaSeperated(journalData[dataIndex].journal_entries.rent_expense) : "" 
          ),
        }
      },
      {
        label: `${mode == 'lessor' ? 'Interest Revenue' : 'Interest Expense'}`,
        name: 'interest',
        options: {
          filter: false,      
          sort: false,
          setCellProps: () => ({style: {minWidth: '150px'}}),
          setCellHeaderProps: () => ({style: {minWidth: '150px'}}),
          customBodyRenderLite:(dataIndex)=>(
            journalData && journalData[dataIndex] && journalData[dataIndex].entries && journalData[dataIndex].entries.interest ? commaSeperated(journalData[dataIndex].entries.interest) :
            journalData && journalData[dataIndex] && journalData[dataIndex].journal_entries && journalData[dataIndex].journal_entries.interest ? commaSeperated(journalData[dataIndex].journal_entries.interest) : ""
          ),
        }
      },
      {
        label: `${mode == 'lessor' ? 'Lease Revenue' : 'Amort'}`,
        name: 'amort',
        options: {
          filter: false,       
          sort: false,
          setCellProps: () => ({style: {minWidth: '100px'}}),
          setCellHeaderProps: () => ({style: {minWidth: '100px'}}),
          customBodyRenderLite:(dataIndex)=>(
            journalData && journalData[dataIndex] && journalData[dataIndex].entries && journalData[dataIndex].entries.amort ? commaSeperated(journalData[dataIndex].entries.amort) :
            journalData && journalData[dataIndex] && journalData[dataIndex].journal_entries && journalData[dataIndex].journal_entries.amort ? commaSeperated(journalData[dataIndex].journal_entries.amort) : ""
          ),
        }
      },
      {
        label: 'Operating Variable Lease Expense',
        name: 'operating_variable_lease_expense',
        options: {
          filter: false,      
          sort: false,
          setCellProps: () => ({style: {minWidth: '250px'}}),
          setCellHeaderProps: () => ({style: {minWidth: '250px'}}),
          customBodyRenderLite:(dataIndex)=>(
            journalData && journalData[dataIndex] && journalData[dataIndex].entries && journalData[dataIndex].entries.operating_variable_lease_expense ? commaSeperated(journalData[dataIndex].entries.operating_variable_lease_expense) :
            journalData && journalData[dataIndex] && journalData[dataIndex].journal_entries && journalData[dataIndex].journal_entries.operating_variable_lease_expense ? commaSeperated(journalData[dataIndex].journal_entries.operating_variable_lease_expense) : ""
          ),
        }
      },
      {
        label: 'Financing Variable Lease Expense',
        name: 'financing_variable_lease_expense',
        options: {
          filter: false,      
          sort: false,
          setCellProps: () => ({style: {minWidth: '250px'}}),
          setCellHeaderProps: () => ({style: {minWidth: '250px'}}),
          customBodyRenderLite:(dataIndex)=>(
            journalData && journalData[dataIndex] && journalData[dataIndex].entries && journalData[dataIndex].entries.financing_variable_lease_expense ? commaSeperated(journalData[dataIndex].entries.financing_variable_lease_expense) :
            journalData && journalData[dataIndex] && journalData[dataIndex].journal_entries && journalData[dataIndex].journal_entries.financing_variable_lease_expense ? commaSeperated(journalData[dataIndex].journal_entries.financing_variable_lease_expense) : ""
          ),
        }
      },
      {
        label: 'Modification Loss or Gain',
        name: 'modification',
        options: {
          filter: false,      
          sort: false,
          setCellProps: () => ({style: {minWidth: '200px'}}),
          setCellHeaderProps: () => ({style: {minWidth: '200px'}}),
          customBodyRenderLite:(dataIndex)=>(
            journalData && journalData[dataIndex] && journalData[dataIndex].entries && journalData[dataIndex].entries.modification ? commaSeperated(journalData[dataIndex].entries.modification) :
            journalData && journalData[dataIndex] && journalData[dataIndex].journal_entries && journalData[dataIndex].journal_entries.modification ? commaSeperated(journalData[dataIndex].journal_entries.modification) : ""
          ),
        }
      },
      {
        label: 'Impairment',
        name: 'impairment',
        options: {
          filter: false,  
          sort: false,
          setCellProps: () => ({style: {minWidth: '100px'}}),
          setCellHeaderProps: () => ({style: {minWidth: '100px'}}),
          customBodyRenderLite:(dataIndex)=>(
            journalData && journalData[dataIndex] && journalData[dataIndex].entries && journalData[dataIndex].entries.impairment ? commaSeperated(journalData[dataIndex].entries.impairment) :
            journalData && journalData[dataIndex] && journalData[dataIndex].journal_entries && journalData[dataIndex].journal_entries.impairment ? commaSeperated(journalData[dataIndex].journal_entries.impairment) : ""
          ),
        }
      },
      {
        label: 'Transition',
        name: 'transition',
        options: {
          filter: false,     
          sort: false,
          setCellProps: () => ({style: {minWidth: '100px'}}),
          setCellHeaderProps: () => ({style: {minWidth: '100px'}}),
          customBodyRenderLite:(dataIndex)=>(
            journalData && journalData[dataIndex] && journalData[dataIndex].entries && journalData[dataIndex].entries.transition ? commaSeperated(journalData[dataIndex].entries.transition) :
            journalData && journalData[dataIndex] && journalData[dataIndex].journal_entries && journalData[dataIndex].journal_entries.transition ? commaSeperated(journalData[dataIndex].journal_entries.transition) : ""
          ),
        }
      },
      {
        label: 'Non leasing Component',
        name: 'nonlease',
        options: {
          filter: false,       
          sort: false,
          setCellProps: () => ({style: {minWidth: '200px'}}),
          setCellHeaderProps: () => ({style: {minWidth: '200px'}}),
          customBodyRenderLite:(dataIndex)=>(
            journalData && journalData[dataIndex] && journalData[dataIndex].entries && journalData[dataIndex].entries.nonlease ? commaSeperated(journalData[dataIndex].entries.nonlease) :
            journalData && journalData[dataIndex] && journalData[dataIndex].journal_entries && journalData[dataIndex].journal_entries.nonlease ? commaSeperated(journalData[dataIndex].journal_entries.nonlease) : ""
          ),
        }
      },
  ]

    useEffect(()=>{
      if(sp.get("id")){
        setFilter(false);
        getSearchResult(sp.get("id"), 'delete')
      }
    },[location.search])

    useEffect(()=>{
      if(searchResult && searchResult._id && !isProcessingSearch){
        getSearchResult(searchResult._id, 'delete')
      }
    },[isProcessingSearch])

    useEffect(()=>{
      if(mode && !filter){
        setFilter(true)
        setJournalData([]);
      }
    },[mode])

    const handleChangeJeType = (e) => {
      setJEType(e.target.value)
    }

    const getClientsData = async(filter) =>{
        setLesseeClients([]);
        setLessorClients([]);
        setLoading(true);
        try {
            const {data} = await axios.get(`${AppConfig.baseUrl}/user_client/get_users_client?user_id=${userData.id}&access_type=${filter.access_type || ""}&search=${filter.search || ""}`,{
                headers: { token: localStorage.getItem("token") }
            });
            if(!data.error){
                const lessee = data.clientData ? data.clientData.filter(l => l.client_id.client_type == 'lessee') : []
                setLesseeClients(lessee);
                const lessor = data.clientData ? data.clientData.filter(l => l.client_id.client_type == 'lessor') : []
                setLessorClients(lessor);
            }
            else{
                throw new Error(data.title);
            }
        } catch (error) {
            toast.error(error.message || 'Something went wrong');
        }
        setLoading(false)
    }
  
    const getJournalData = async(start, end, leases, clients) =>{
        setLoadingJournal(true);
        try {
            const body = {
                start_date: moment(start).format("YYYY-MM-DD"),
                end_date: moment(end).format("YYYY-MM-DD"),
                lease_ids: leases,
                type: jeType,
                clients,
                mode: mode ? mode : 'lessee',
                je_type: 'fsli',
                jeExportType: jeExportType ? jeExportType : 'monthly_aggregate'
            }
            const {data} = await axios.post(`${AppConfig.baseUrl}/journal_entries/get_journal_entries`,body, {
                headers: { token: localStorage.getItem("token") }
            });
            if(!data.error){
              getSearchResult(data.searchData._id);
              reloadProcessingSearch();
            }else{
              throw new Error(data.title);
            }
        } catch (error) {
            toast.error(error.message || 'Something went wrong');
            setLoadingJournal(false)
        }
    }
  
    useEffect(() => {
        if(userData && userData.id){
            getClientsData({});
        }
    }, [userData])

    const flip = (selectedLeases, startDate, endDate) => {
        let uniqueClients = [];
        let ids = []
        for(const lease of selectedLeases){
            ids.push(lease._id)
            const client = lease.client_id
            const index = uniqueClients.findIndex(v => v === lease.client_id._id)
            if(index === -1){
                uniqueClients.push(client._id);
            }
        }
        setFilter(false);
        getJournalData(startDate, endDate, ids, uniqueClients);
    }

    const getSearchResult = async(id, type) => {
      setLoadingJournal(true);
      setFilter(false)
      setJournalData([]);
      try {
        const {data} = await axios.get(`${AppConfig.baseUrl}/search_query?id=${id}&type=${type}&mode=${mode ? mode : 'lessee'}`,{
          headers: { token: localStorage.getItem("token") }
        });
        if(!data.error){
          setSearchResult(data.searchResult)
          let chunkData = data.searchResult && data.searchResult.result && data.searchResult.result.journal ? data.searchResult.result.journal : []
          if(data.searchChunk && data.searchChunk.length > 0 ){
            for(let v of data.searchChunk){
              if(v.result && v.result.journal && v.result.journal.length > 0){
                chunkData = [...chunkData,...v.result.journal]
              }
            }
          }
          const total = data.searchResult && data.searchResult.result && data.searchResult.result.journalTotal && data.searchResult.result.journalTotal.length > 0 ? data.searchResult.result.journalTotal[0] : {}
          const value = [{_id:null,client_id:{client: ''},lease_id: {name: '', reporting_standard: '', classification: ''},currency:{code: '', symbol: ''},
          entries: {amort: total.amort, description: '',financing_variable_lease_expense : total.financing_variable_lease_expense,impairment: total.impairment,interest: total.interest,lease_liability: total.lease_liability,
          modification: total.modification,nonlease: total.nonlease,operating_variable_lease_expense: total.operating_variable_lease_expense, payment_suspense_account_before_commencement: total.payment_suspense_account_before_commencement,rent_deposit: total.rent_deposit,rent_expense: total.rent_expense,
          rent_payment_suspense_account: total.rent_payment_suspense_account,rou_asset: total.rou_asset,transition: total.transition}, differed_value:total.differed_value}]
          if(chunkData && chunkData.length > 0){
            chunkData = [...chunkData,...value]
          }
          setIndex(chunkData.length - 1)
          setJournalData(chunkData);
        }
        else{
          throw new Error(data.title);
        }
      } catch (error) {
        toast.error(error.message || 'Something went wrong');
      }
      setLoadingJournal(false);
    }
    const downloadCSVFile = () => {
        var ExcelJSWorkbook = new ExcelJS.Workbook();
        var worksheet = ExcelJSWorkbook.addWorksheet("Journal_Entries");
        worksheet.getColumn("A").width = 10
        worksheet.getColumn("B").width = 10
        worksheet.getColumn("C").width = 10
        worksheet.getColumn("D").width = 10
        worksheet.getColumn("E").width = 10
        worksheet.getColumn("F").width = 10
        worksheet.getColumn("G").width = 10
        worksheet.getColumn("H").width = 10
        worksheet.getColumn("I").width = 10
        worksheet.getColumn("J").width = 10
        worksheet.getColumn("K").width = 10
        worksheet.getColumn("L").width = 10
        worksheet.getColumn("M").width = 10
        worksheet.getColumn("N").width = 10
        worksheet.getColumn("O").width = 10
        worksheet.getColumn("P").width = 10
        worksheet.getColumn("Q").width = 10
        worksheet.getColumn("R").width = 10
        worksheet.getColumn("S").width = 10
        worksheet.getColumn("T").width = 10
        if(mode != 'lessor') worksheet.getColumn("U").width = 10
        
        worksheet.getCell("A1").value = `Date`        
        worksheet.getCell("B1").value = `Client`        
        worksheet.getCell("C1").value = `Lease Name`        
        worksheet.getCell("D1").value = `Reporting Standard`        
        worksheet.getCell("E1").value = `Classification`        
        worksheet.getCell("F1").value = `Description` 
        worksheet.getCell("G1").value = `Currency`
        worksheet.getCell("H1").value = `${mode == 'lessor' ? 'Receipt Suspense Account Before Commencement' : 'Payment Suspense Account Before Commencement'}`
        worksheet.getCell("I1").value = `Rent Deposit`
        worksheet.getCell("J1").value = `${mode == 'lessor' ? "Rent Receipt Suspense Account" : "Rent Payment Suspense Account"}`
        worksheet.getCell("K1").value = `${mode == 'lessor' ? "Deferred inflow of resources" : "Rou Asset"}`
        worksheet.getCell("L1").value = `${mode == 'lessor' ? "Receivable" : "Lease liability"}`
        worksheet.getCell("M1").value = `Lease asset-liability`
        worksheet.getCell("N1").value = `${mode == 'lessor' ? 'Rent Revenue' : 'Rent Expense'}`
        worksheet.getCell("O1").value = `${mode == 'lessor' ? 'Interest Revenue' : 'Interest Expense'}`
        worksheet.getCell("P1").value = `${mode == 'lessor' ? 'Lease Revenue' : 'Amort'}`
        if(mode != 'lessor') worksheet.getCell("Q1").value = `Operating Variable Lease Expense`
        worksheet.getCell(mode == 'lessor' ? "Q1" : "R1").value = `${mode == 'lessor' ? 'Financing Variable Lease Revenue' : 'Financing Variable Lease Expense'}`
        worksheet.getCell(mode == 'lessor' ? "R1" : "S1").value = `Modification Loss or Gain`
        if(mode != 'lessor') worksheet.getCell("T1").value = `Impairment`
        worksheet.getCell(mode == 'lessor' ? "S1" : "U1").value = `Transition`
        worksheet.getCell(mode == 'lessor' ? "T1" : "V1").value = `Non leasing Component`
        
        journalData && journalData.length > 0 && journalData.map((value, i) => {
          if(mode == 'lessor'){
            worksheet.addRow([ value.journal_entries ? new Date(value.journal_entries.date) : new Date(value.entries.date), value.client_id.client,value.lease_id.name, value.lease_id.reporting_standard, value && value.lease_id && value.lease_id.classification ? capitalize(value.lease_id.classification) : "",
            value && value.entries && value.entries.description && value.entries.description == "Initial Recognition of Lease Liability" ? "Initial Recognition of Lease" : value && value.entries && value.entries.description && value.entries.description == "Finance Lease Rent Expense" ? "Finance Lease Income" : value && value.entries && value.entries.description ? value.entries.description : '', 
            value && value.currency && value.currency.symbol ? value.currency.symbol + '('+ value.currency.code +')' : "$ (USD)", 
            value && value.entries && value.entries.payment_suspense_account_before_commencement ? Number(value.entries.payment_suspense_account_before_commencement) : value && value.journal_entries && value.journal_entries.payment_suspense_account_before_commencement ? Number(value.journal_entries.payment_suspense_account_before_commencement): "",
            value && value.entries && value.entries.rent_deposit ? Number(value.entries.rent_deposit) : value && value.journal_entries && value.journal_entries.rent_deposit ? Number(value.journal_entries.rent_deposit) :  "", 
            value && value.entries && value.entries.rent_payment_suspense_account ? Number(value.entries.rent_payment_suspense_account) : value && value.journal_entries && value.journal_entries.rent_payment_suspense_account ? Number(value.journal_entries.rent_payment_suspense_account) : "",
            value && value.entries && value.entries.rou_asset ? Number(value.entries.rou_asset) : value && value.journal_entries && value.journal_entries.rou_asset ? Number(value.journal_entries.rou_asset) : "",
            value && value.entries && value.entries.lease_liability ? Number(value.entries.lease_liability) : value && value.journal_entries && value.journal_entries.lease_liability ? Number(value.journal_entries.lease_liability) : "",
            value && value.differed_value ? Number(value.differed_value) : "",
            value && value.entries && value.entries.rent_expense ? Number(value.entries.rent_expense) : value && value.journal_entries && value.journal_entries.rent_expense ? Number(value.journal_entries.rent_expense) : "" ,
            value && value.entries && value.entries.interest ? Number(value.entries.interest) : value && value.journal_entries && value.journal_entries.interest ? Number(value.journal_entries.interest) : "",
            value && value.entries && value.entries.amort ? Number(value.entries.amort) : value && value.journal_entries && value.journal_entries.amort ? Number(value.journal_entries.amort) : "",
            value && value.entries && value.entries.financing_variable_lease_expense ? Number(value.entries.financing_variable_lease_expense) : value && value.journal_entries && value.journal_entries.financing_variable_lease_expense ? Number(value.journal_entries.financing_variable_lease_expense) : "",
            value && value.entries && value.entries.modification ? Number(value.entries.modification) : value && value.journal_entries && value.journal_entries.modification ? Number(value.journal_entries.modification) : "",
            value && value.entries && value.entries.transition ? Number(value.entries.transition) : value && value.journal_entries && value.journal_entries.transition ? Number(value.journal_entries.transition) : "",
            value && value.entries && value.entries.nonlease ? Number(value.entries.nonlease) : value && value.journal_entries && value.journal_entries.nonlease ? Number(value.journal_entries.nonlease) : "" ]);
          }else{
            worksheet.addRow([ value.journal_entries ? new Date(value.journal_entries.date) : new Date(value.entries.date), value.client_id.client,value.lease_id.name, value.lease_id.reporting_standard, value && value.lease_id && value.lease_id.classification ? capitalize(value.lease_id.classification) : "",
            value && value.entries && value.entries.description ? value.entries.description : "", value && value.currency && value.currency.symbol ? value.currency.symbol + '('+ value.currency.code +')' : "$ (USD)", 
            value && value.entries && value.entries.payment_suspense_account_before_commencement ? Number(value.entries.payment_suspense_account_before_commencement) : value && value.journal_entries && value.journal_entries.payment_suspense_account_before_commencement ? Number(value.journal_entries.payment_suspense_account_before_commencement): "",
            value && value.entries && value.entries.rent_deposit ? Number(value.entries.rent_deposit) : value && value.journal_entries && value.journal_entries.rent_deposit ? Number(value.journal_entries.rent_deposit) :  "", 
            value && value.entries && value.entries.rent_payment_suspense_account ? Number(value.entries.rent_payment_suspense_account) : value && value.journal_entries && value.journal_entries.rent_payment_suspense_account ? Number(value.journal_entries.rent_payment_suspense_account) : "",
            value && value.entries && value.entries.rou_asset ? Number(value.entries.rou_asset) : value && value.journal_entries && value.journal_entries.rou_asset ? Number(value.journal_entries.rou_asset) : "",
            value && value.entries && value.entries.lease_liability ? Number(value.entries.lease_liability) : value && value.journal_entries && value.journal_entries.lease_liability ? Number(value.journal_entries.lease_liability) : "",
            value && value.differed_value ? Number(value.differed_value) : "",
            value && value.entries && value.entries.rent_expense ? Number(value.entries.rent_expense) : value && value.journal_entries && value.journal_entries.rent_expense ? Number(value.journal_entries.rent_expense) : "" ,
            value && value.entries && value.entries.interest ? Number(value.entries.interest) : value && value.journal_entries && value.journal_entries.interest ? Number(value.journal_entries.interest) : "",
            value && value.entries && value.entries.amort ? Number(value.entries.amort) : value && value.journal_entries && value.journal_entries.amort ? Number(value.journal_entries.amort) : "",
            value && value.entries && value.entries.operating_variable_lease_expense ? Number(value.entries.operating_variable_lease_expense) : value && value.journal_entries && value.journal_entries.operating_variable_lease_expense ? Number(value.journal_entries.operating_variable_lease_expense) : "",
            value && value.entries && value.entries.financing_variable_lease_expense ? Number(value.entries.financing_variable_lease_expense) : value && value.journal_entries && value.journal_entries.financing_variable_lease_expense ? Number(value.journal_entries.financing_variable_lease_expense) : "",
            value && value.entries && value.entries.modification ? Number(value.entries.modification) : value && value.journal_entries && value.journal_entries.modification ? Number(value.journal_entries.modification) : "",
            value && value.entries && value.entries.impairment ? Number(value.entries.impairment) : value && value.journal_entries && value.journal_entries.impairment ? Number(value.journal_entries.impairment) : "",
            value && value.entries && value.entries.transition ? Number(value.entries.transition) : value && value.journal_entries && value.journal_entries.transition ? Number(value.journal_entries.transition) : "",
            value && value.entries && value.entries.nonlease ? Number(value.entries.nonlease) : value && value.journal_entries && value.journal_entries.nonlease ? Number(value.journal_entries.nonlease) : "" ]);
          }
        })
    
        worksheet.getColumn(1).numFmt = 'mm/dd/yyyy'
        worksheet.getColumn(8).numFmt = '##,##,##,##,##0.00'
        worksheet.getColumn(9).numFmt = '##,##,##,##,##0.00'
        worksheet.getColumn(10).numFmt = '##,##,##,##,##0.00'
        worksheet.getColumn(11).numFmt = '##,##,##,##,##0.00'
        worksheet.getColumn(12).numFmt = '##,##,##,##,##0.00'
        worksheet.getColumn(13).numFmt = '##,##,##,##,##0.00'
        worksheet.getColumn(14).numFmt = '##,##,##,##,##0.00'
        worksheet.getColumn(15).numFmt = '##,##,##,##,##0.00'
        worksheet.getColumn(16).numFmt = '##,##,##,##,##0.00'
        worksheet.getColumn(17).numFmt = '##,##,##,##,##0.00'
        worksheet.getColumn(18).numFmt = '##,##,##,##,##0.00'
        worksheet.getColumn(19).numFmt = '##,##,##,##,##0.00'
        worksheet.getColumn(20).numFmt = '##,##,##,##,##0.00'
        if(mode != 'lessor')worksheet.getColumn(21).numFmt = '##,##,##,##,##0.00'
        if(mode != 'lessor')worksheet.getColumn(22).numFmt = '##,##,##,##,##0.00'
    
        ExcelJSWorkbook.xlsx.writeBuffer().then(function(buffer) {
            var csv_file, download_link;
            csv_file = new Blob([buffer], { type: "application/octet-stream" });
            download_link = document.createElement("a");
            download_link.download = "Journal_Entries.xlsx" ;
            download_link.href = window.URL.createObjectURL(csv_file);
            download_link.style.display = "none";
            document.body.appendChild(download_link);
            download_link.click();
        });
    }

    return (
        <Layout>
            {filter ?
                <div className="page-content">
                    <Helmet>
                        <title>LeaseJava | Journal Entries</title>
                    </Helmet>
                    <Container fluid>
                        <Breadcrumbs title="journal_filter"
                            name="FSLI - Column view"
                            type="journal_filter"
                        />
                    </Container>
                    <Filter handleClick={flip} type="journal_entries" clientsData={mode == 'lessor' ? lessorClients : lesseeClients} loading={loading} jeType={jeType} accountType={accountType} setAccountType={setAccountType} setJEType={setJEType} handleChangeJeType={handleChangeJeType} getSearchResult={getSearchResult} jeExportType={jeExportType} setJeExportType={setJeExportType} handleChangeType={handleChangeType}/> 
                </div>
                :
                <div className="page-content">
                    <Helmet>
                        <title>LeaseJava | Journal Entries</title>
                    </Helmet>
                    <Container fluid>
                        <Breadcrumbs title="journal"
                            name1="FSLI - Column view"
                            type="journal"
                        />
                        {searchResult && Object.keys(searchResult).length > 0 &&
                          <Row>
                            <Col md="6">
                              <Card>
                                  <CardBody>
                                    <Row>
                                      <Col xs="4">
                                          <h6>Date range:</h6>
                                      </Col>
                                      <Col xs="8" style={{textAlign: 'right'}}>
                                          {moment(searchResult.startDate).utc().format('MM/DD/YYYY')}-{moment(searchResult.endDate).utc().format('MM/DD/YYYY')}
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col xs="4">
                                          <h6>Type:</h6>
                                      </Col>
                                      <Col xs="8" style={{textAlign: 'right'}}>
                                          {searchResult.je_type.toUpperCase()}
                                      </Col>
                                    </Row>
                                  </CardBody>
                              </Card>
                            </Col>
                            <Col md="6">
                              <Card>
                                  <CardBody>
                                    <Row>
                                      <Col xs="4">
                                          <h6>Clients:</h6>
                                      </Col>
                                      <Col xs="8" style={{textAlign: 'right'}}>
                                          {searchResult.clients && searchResult.clients.length > 1 ? searchResult.clients[0].client+", "+searchResult.clients[1].client+(searchResult.clients.length > 2 ? " + "+(searchResult.clients.length - 2)+" others" : '') : searchResult.clients && searchResult.clients.length > 0 ? searchResult.clients[0].client : ''}
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col xs="4">
                                          <h6>Leases:</h6>
                                      </Col>
                                      <Col xs="8" style={{textAlign: 'right'}}>
                                          {searchResult.lease_ids && searchResult.lease_ids.length > 1 ? searchResult.lease_ids[0].name+", "+searchResult.lease_ids[1].name+(searchResult.lease_ids.length > 2 ? " + "+(searchResult.lease_ids.length - 2)+" others" : '') : searchResult.lease_ids && searchResult.lease_ids.length > 0 ? searchResult.lease_ids[0].name : ''}
                                      </Col>
                                    </Row>
                                  </CardBody>
                              </Card>
                            </Col>
                          </Row>
                        }
                        <Row>
                          <Col>
                            <Card>
                                <CardBody>
                                    <ThemeProvider theme={createTheme({components: {...getMuiTheme(),
                                      MUIDataTableToolbar:{
                                        styleOverrides:{
                                          actions: {
                                            display: 'contents'
                                          }
                                        }
                                      }
                                    }})}>
                                      <MUIDataTable
                                        title={
                                          <Form>
                                            <div className="d-flex mb-3 justify-content-end">
                                                <button type="button" disabled={loadingJournal} onClick={()=>{downloadCSVFile()}} className="btn btn-lbusers btn-md">
                                                    {
                                                        loadingJournal && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> 
                                                    }
                                                    EXPORT CSV
                                                </button>&nbsp;&nbsp;
                                            </div>
                                          </Form>
                                        }
                                        data={journalData}
                                        columns={columns}
                                        options={{ 
                                          rowsPerPage:10, 
                                          print:false, 
                                          download:false, 
                                          filter:false, 
                                          pagination:false,
                                          selectableRows:"none", 
                                          search:false, 
                                          rowsPerPageOptions:[], 
                                          responsive:'scroll',
                                          textLabels: {
                                            body: { 
                                              noMatch: loadingJournal ? <Spinner color="primary" className="text-lbusers" /> : !loadingJournal && searchResult && searchResult.is_processing ? <div><h6>Your data is being processed</h6><Spinner color="primary" className="text-lbusers" /></div> : "No data found"
                                            },
                                            viewColumns: {
                                              title: "",
                                            },
                                          },
                                          rowHover: false,
                                          setRowProps: (row, dataIndex, rowIndex) => {
                                            return {
                                              style: { backgroundColor: rowIndex == index ? "#edf0f5" : rowIndex % 2 === 0 ? '#ffffff' : '#eeeeee',position: rowIndex == index ? 'sticky' : '',bottom: rowIndex == index ? 0 : ''},
                                            };
                                          },
                                        }}
                                      />
                                    </ThemeProvider>
                                </CardBody>
                            </Card>
                          </Col>
                        </Row>
                    </Container>
                </div>
            }
        </Layout>
    )
}

export default JournalEntries